import React, { useState, useEffect } from 'react';
import { i18n } from '../../i18n';
import {RepeatIcon} from "../../appearence/icons/repeat_icon";
import {PenIcon} from "../../appearence/icons/pen_icon";
import {TrashIcon} from "../../appearence/icons/trash_icon";
import {CloseIcon} from "../../appearence/icons/close_icon";
import SelectList from '../sharedComponents/SelectList';
import {
    registerObserver,
    unRegisterObserver,
    sendWSMessage,
    WEBSOCKET_EVENT_TYPE_MEAL_INGREDIENT,
} from '../sharedComponents/WebSocketConnectionService';
import {GET_USER_REQUEST, getFetchRequest, MEAL_URL_PART} from "../../store/requests";
import {errorCallback, makeUrl} from "../../util";
import {userService} from "../../services/userService";
import {
    deleteIngredient,
    filterIngredients,
    getIngredientReplaced,
    isIngredientReplaced,
    setIngredientToReplace,
} from "../../services/mealEditService";
import Loading from "../Loading";

const createTitle = (ingredientParts) => {
    return ingredientParts.map(it => {
        return i18n.get(`product.item.code.${it.productCode}`);
    }).join(', ');
};

const EditIngredientHandler = ({ mealId, ingredient, handleClose }) => {
    const [possibleIngredients, setPossibleIngredients] = useState([]);
    const [currentIngredient, setCurrentIngredient] = useState(isIngredientReplaced(mealId, ingredient.id) ? getIngredientReplaced(mealId, ingredient.id) : ingredient);
    const selectAnItem = (e, selectedIngredient) => {
        e.stopPropagation();
        console.log("-----------------------------selectAnItem");
        console.log(selectedIngredient);
        setIngredientToReplace(mealId, ingredient.id, possibleIngredients.find(it => it.id === selectedIngredient.id));
        setCurrentIngredient(possibleIngredients.find(it => it.id === selectedIngredient.id));
    };
    useEffect(() => {
        getFetchRequest(makeUrl([GET_USER_REQUEST, userService.getUser().id, `${MEAL_URL_PART}${mealId}/ingredient/?withPossible=true`]),
            (response) => {
                setPossibleIngredients(response.filter(it => it.ingredientGroup === ingredient.ingredientGroup));
            }, errorCallback);
    }, []);

    return (
        <>
            <span className="block__content ingredient__content">{createTitle(currentIngredient.ingredientParts)}</span>
            <CloseIcon nameOfClass="icon ingredient__action-icon" handleClick={handleClose}/>
            {possibleIngredients.length ?
                <SelectList optionsList={possibleIngredients.map(it => {
                    return {
                        id: it.id,
                        name: createTitle(it.ingredientParts),
                    };
                })} selectAnItem={selectAnItem} /> :
                null
            }
        </>
    );
};

const Ingredient = ({mealId, ingredient}) => {
    const [isEditable, setIsEditable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const getIngredientTitle = () => {
        if (isIngredientReplaced(mealId, ingredient.id)) {
            return createTitle(getIngredientReplaced(mealId, ingredient.id).ingredientParts);
        }
        return createTitle(ingredient.ingredientParts);
    };
    const handleEditIngredient = () => {
        setIsEditable(!isEditable);
    };
    const handleRecreatePlanItemIngredient = (e) => {
        e.stopPropagation();
        setIsLoading(true);
        sendWSMessage(WEBSOCKET_EVENT_TYPE_MEAL_INGREDIENT, {
            "mealId": mealId,
            "ingredientId": ingredient.id,
        });
    };
    const getWebSocketMessage = (message) => {
        console.log("----------------------------------------getWebSocketMessage 2");
        try {
            const data = JSON.parse(message);
            console.log(data.event);
            console.log(data.event === WEBSOCKET_EVENT_TYPE_MEAL_INGREDIENT);
            if (data.event === WEBSOCKET_EVENT_TYPE_MEAL_INGREDIENT) {
                const newMealIngredient = data.body;
                console.log(newMealIngredient.id === ingredient.id);
                if(newMealIngredient.id === ingredient.id) {
                    console.log(newMealIngredient);
                    setIngredientToReplace(mealId, ingredient.id, newMealIngredient);
                    setIsLoading(false);
                    console.log("---------------------setIngredientToReplace");
                }
            }
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        registerObserver(getWebSocketMessage);
        return () => {
            unRegisterObserver(getWebSocketMessage);
        };
    }, []);
    const handleDeleteIngredient = () => {
        console.log("---------------------------handleDeleteIngredient");
        console.log(mealId);
        console.log(ingredient.id);
        deleteIngredient(mealId, ingredient.id);
        setIsDeleted(true);
    };
    return (
        <>
            {isDeleted === false ?
                <li key={ingredient.id} className={`ingredient__container ${isLoading ? "ingredient__container-loading" : ""}`}>
                    {isEditable ?
                        <EditIngredientHandler mealId={mealId} ingredient={ingredient} handleClose={handleEditIngredient}/>
                        :
                        isLoading ?
                            <Loading nameOfClass="ingredient-loading" />
                            :
                            <>
                                <span className="block__content ingredient__content">{getIngredientTitle()}</span>
                                <PenIcon nameOfClass="icon ingredient__action-icon" handleClick={handleEditIngredient}/>
                                <RepeatIcon nameOfClass="icon ingredient__action-icon" handleClick={handleRecreatePlanItemIngredient}/>
                                <TrashIcon nameOfClass="icon ingredient__action-icon" handleClick={handleDeleteIngredient}/>
                            </>
                    }
                </li>
                :
                null
            }
        </>
    );
};

const MealPlanEditableIngredientsList = ({ mealId, ingredients }) => {
    try {
        return (
            <ul className="plan__ingredients plan__ingredients-editable">
                {filterIngredients(mealId, ingredients).map((it) => (
                    <Ingredient key={it.id} mealId={mealId} ingredient={it} />
                ))}
            </ul>
        );
    } catch(e) {
        console.log(e);
        return null;
    }
};

export default MealPlanEditableIngredientsList;
