import React, { useState, useEffect } from 'react';
import LoginPage from './components/LoginPage';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { GET_CURRENT_USER_REQUEST, LOCALES_URL, getFetchRequest } from './store/requests';
import { ACTION_NEED_TO_SHOW_ON_BOARDING, CHANGE_VISIBILITY_EVENT, LOCALE_EN, LOCALE_RU, VISIBILITY_STATE_VISIBLE } from './constant';
import PlanPage from './components/PlanPage';
import ProfilePage from './components/ProfilePage';
import WithWrapper from './components/WithMenu';
import PreferencePage from './components/PreferencePage';
import Onboarding from './components/Onboarding';
import { i18n } from './i18n';
import { handleCreateNewWebSocketAsyncService, registerObserver, unRegisterObserver } from './components/sharedComponents/WebSocketConnectionService';
import { userService } from './services/userService';
import { broadcastChannelService } from './components/sharedComponents/broadcastChannelService';
import InitialPreferences from './components/initial-preferences/InitialPreferences';
import {makeUrl, successCallbackEmpty} from "./util";

const App = () => {
    const [accessToken, setAccessToken] = useState(null);
    const [localeIsReady, setLocaleIsReady] = useState(false);
    const navigate = useNavigate();
    const successCallback = async (result) => {
        try{
            result.id ? setAccessToken(true) : setAccessToken(false);
            i18n.APP_LOCALE = result.locale;
            if(result.needActions && result.needActions.includes(ACTION_NEED_TO_SHOW_ON_BOARDING)) {
                navigate('/onboarding');
            }
        } catch(err) {
            setAccessToken(false);
        }
    };

    const errorCallback = (err) => {
        console.log(err);
        setAccessToken(false);
    }; 

    const successGetLocaleCallback = (response) => {
        i18n.create(response, LOCALE_RU);
        getFetchRequest(makeUrl([LOCALES_URL, LOCALE_EN, '/']), (response) => {
            i18n.create(response, LOCALE_EN);
            setLocaleIsReady(true);
        }, (err) => {
            setLocaleIsReady(true);
            errorCallback(err);
        });
    }; 

    useEffect(()=> {
        userService.loadUser(successCallback, errorCallback);
        getFetchRequest(makeUrl([LOCALES_URL, LOCALE_RU, '/']), successGetLocaleCallback, (err) => {
            setLocaleIsReady(true);
            errorCallback(err);
        });
    },[]);

    const changeWSMessage = (message) => {
        console.log("--------------------------------------------------");
        console.log(message);
    };

    useEffect(() => {
        broadcastChannelService.createLocaleConnection();        
        document.addEventListener(CHANGE_VISIBILITY_EVENT, () => {
            if (document.visibilityState == VISIBILITY_STATE_VISIBLE) {
                getFetchRequest(makeUrl([GET_CURRENT_USER_REQUEST]), successCallbackEmpty, errorCallback);
            } 
        });
    }, []);

    useEffect(() => {
        if(accessToken) {
            handleCreateNewWebSocketAsyncService();
            registerObserver(changeWSMessage);
        }
        return () => unRegisterObserver(changeWSMessage);
    }, [accessToken]);

    const PlanPageWithMenu = WithWrapper(PlanPage, setAccessToken);
    const ProfilePageWithMenu = WithWrapper(ProfilePage, setAccessToken);
    const PreferencePageWithMenu = WithWrapper(PreferencePage, setAccessToken);
    const OnboardingWithMenu = WithWrapper(Onboarding, setAccessToken);

    if(accessToken === null || !localeIsReady) {
        return null;
    } else if (accessToken === false) {
        return (
            <Routes>
                <Route exact path="*" element={<LoginPage setAccessToken={setAccessToken}/>} />
            </Routes>
        );
    } else {
        return (
            <Routes> 
                <Route path="*" element={<Navigate to="/plan" replace />} />
                <Route path="/onboarding" element={<OnboardingWithMenu />} />
                <Route path="/initial-preferences" element={<InitialPreferences />} />
                <Route exact path="/plan" element={<PlanPageWithMenu />} />
                <Route exact path="/profile" element={<ProfilePageWithMenu />} />
                <Route exact path="/preference/:tab/:id" element={<PreferencePageWithMenu />}  />
                <Route exact path="/login" element={<LoginPage setAccessToken={setAccessToken}/>} />
            </Routes>
        );
    }
};

export default App;
