import React from 'react';

const Loading = ({nameOfClass}) => (
    <div className={`loading ${nameOfClass ? nameOfClass : ""}`}>
        <span className='loading__item'></span>
        <span className='loading__item'></span>
        <span className='loading__item'></span>
        <span className='loading__item'></span>
        <span className='loading__item'></span>
        <span className='loading__item'></span>
    </div>
);

export default Loading;
