import React, { useState, useEffect } from 'react';
import { POPUP_KEY_DELETE_USER_PREFERENCE } from '../../constant';
import { popupOpenService } from '../sharedComponents/popupOpenService';
import { GET_CURRENT_USER_REQUEST, GET_USER_REQUEST, PREFERENCE_URL_PART, deleteFetchRequest, getFetchRequest } from '../../store/requests';
import { MODAL_SMALL_SIZE } from '../helper';
import Modal from '../Modal';
import { i18n } from '../../i18n';
import {errorCallback, makeUrl} from "../../util";

const DeleteModalContent = ({ handleDelete, handleClose }) => (
    <>
        <h2 className="modal__title modal__title_center block__title-h3">{i18n.get('app.page.preference.modal.title')}</h2>
        <p className="block__content modal__text modal__text_center">{i18n.get('app.page.preference.modal.content')}</p>
        <div className="modal__button-group">
            <button className="block__button button button_outline" onClick={() => handleClose(null)}>
                <span className="button__content">{i18n.get('app.page.preference.modal.cancel')}</span>
            </button>
            <button className="block__button button" onClick={handleDelete}>
                <span className="button__content">{i18n.get('app.page.preference.modal.delete')}</span>
            </button>
        </div>
    </>
);

const DeletePreferencePopup = ({ notifyPreferencesList }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentPreferenceId, setCurrentPreferenceId] = useState('');

    const handleOpenModal = (formId) => {
        setIsOpen(true);
        setCurrentPreferenceId(formId);
    };    
    
    useEffect(() => {
        popupOpenService.registerPopup(POPUP_KEY_DELETE_USER_PREFERENCE, handleOpenModal);
        return () => popupOpenService.unRegisterPopup(POPUP_KEY_DELETE_USER_PREFERENCE, handleOpenModal);
    },[]);

    const deletePreference = () => {        
        setIsOpen(null);
        setCurrentPreferenceId('');
        getFetchRequest(makeUrl([GET_CURRENT_USER_REQUEST]), (user) => {
            deleteFetchRequest(makeUrl([GET_USER_REQUEST, user.id, PREFERENCE_URL_PART, currentPreferenceId, '/']), 
                () => notifyPreferencesList(), errorCallback);
        }, errorCallback);
    };

    return (
        isOpen ?
            <Modal content={<DeleteModalContent handleClose={setIsOpen} handleDelete={deletePreference} />} 
                handleCloseModal={setIsOpen} size={MODAL_SMALL_SIZE}/>
            :
            null
    );
};

export default DeletePreferencePopup;
