import React, { useState, useEffect, useRef } from 'react';
import { i18n } from '../../i18n';

const LAST_ELEMENT_AVERAGE_WIDTH_PX = 35;

const createTitle = (ingredientParts) => {
    return ingredientParts.map(it => {
        return i18n.get(`product.item.code.${it.productCode}`);
    }).join(', ');
};

const checkIsVisible = (element, container) => {
    const elementRect = element.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    if(elementRect.x > containerRect.x
        && (elementRect.x + elementRect.width) > (containerRect.x + containerRect.width - LAST_ELEMENT_AVERAGE_WIDTH_PX)) {
        return false;
    }
    return true;
};

const MealPlanItemIngredientsList = ({ ingredients }) => {
    const [overflowedCount, setOverflowedCount] = useState(-1);
    const ref = useRef();
    useEffect(() => {
        if(ref.current && ref.current.children) {
            for (let [index, it] of Array.from(ref.current.children).entries()) {
                if(!checkIsVisible(it, ref.current)) {
                    setOverflowedCount(Array.from(ref.current.children).length - index);
                    return;
                }
            }
        }
    }, [ref]);
    return (
        <ul className="plan__ingredients" ref={ref}>
            {ingredients.map((it, index) => {
                return overflowedCount < 0 || (index + overflowedCount) < ingredients.length ? <li key={it.id} className="block__content plan__ingredients-item">{createTitle(it.ingredientParts)}</li> : null;
            })}
            {overflowedCount > 0 ?
                <li className="block__content plan__ingredients-item">
                    +{overflowedCount}
                </li> 
                : 
                null}
        </ul> 
    );
};

export default MealPlanItemIngredientsList;
