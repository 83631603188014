import {deleteFetchRequest, GET_USER_REQUEST, MEAL_URL_PART, postFetchRequest} from "../store/requests";
import {errorCallback, makeUrl} from "../util";
import {userService} from "./userService";

class CallbackCaller {
    callsNumber = 0;
    currentCallsNumber = 0;
    constructor(callsNumber) {
        this.callsNumber = callsNumber;
    }
    increaseCalls() {
        this.currentCallsNumber++;
    }
    runCallBack(callback) {
        if (this.callsNumber === this.currentCallsNumber) {
            callback();
        }
    }
}

class MealEditService {
    mealId = '';
    observersList = [];
    replacingIngredients = new Map();
    deletedIngredients = new Set();

    constructor(mealId) {
        this.mealId = mealId;
    }

    setIngredientToReplace(initialIngredientId, ingredient) {
        this.replacingIngredients.set(initialIngredientId, ingredient);
    }

    isIngredientReplaced(ingredientId) {
        return this.replacingIngredients.has(ingredientId);
    }

    getIngredientReplaced(ingredientId) {
        if (this.isIngredientReplaced(ingredientId)) {
            return this.replacingIngredients.get(ingredientId);
        }
    }

    deleteIngredient(ingredientId) {
        this.deletedIngredients.add(ingredientId);
        console.log(this.deletedIngredients);
    }

    getDeletedIngredients() {
        return this.deletedIngredients;
    }

    saveChanges(callback) {
        const callbackCaller = new CallbackCaller(this.deletedIngredients.size + this.replacingIngredients.size);
        this.deletedIngredients.forEach(ingredientId => {
            deleteFetchRequest(
                makeUrl([GET_USER_REQUEST, userService.getUser().id, `${MEAL_URL_PART}${this.mealId}/ingredient/${ingredientId}/`]),
                () => {
                    callbackCaller.increaseCalls();
                    callbackCaller.runCallBack(callback);
                },
                errorCallback,
            );
        });
        this.replacingIngredients.forEach((ingredient, ingredientId) => {
            postFetchRequest(
                makeUrl([GET_USER_REQUEST, userService.getUser().id, `${MEAL_URL_PART}${this.mealId}/ingredient/${ingredientId}/`]),
                ingredient,
                () => {
                    callbackCaller.increaseCalls();
                    callbackCaller.runCallBack(callback);
                },
                errorCallback,
            );
        });
        this.clean();
        this.notifyItemsChangingObservers();
    }

    clean() {
        this.replacingIngredients = new Map();
        this.deletedIngredients = new Set();
    }

    registerItemsChangingObserver(observer) {
        this.observersList.push(observer);
    }

    unRegisterItemsChangingObserver(observer) {
        try {
            this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
        } catch (err) {
            console.log(err);
        }
    }

    notifyItemsChangingObservers() {
        this.observersList.forEach(observer => observer());
    }
}

const mealServices = new Map();

export const initMealEditService = (mealId) => {
    mealServices.set(mealId, new MealEditService(mealId));
    return mealServices.get(mealId);
};

export const saveMealChanges = (mealId, callback) => {
    if(mealServices.has(mealId)) {
        mealServices.get(mealId).saveChanges(callback);
    }
};

export const cleanMealEditService = (mealId) => {
    if(mealServices.has(mealId)) {
        mealServices.get(mealId).clean();
    }
};

export const removeMealEditService = (mealId) => {
    mealServices.delete(mealId);
};

export const unRegisterItemsChangingObserver = (mealId, observer) => {
    if(mealServices.has(mealId)) {
        mealServices.get(mealId).unRegisterItemsChangingObserver(observer);
    }
};

export const filterIngredients = (mealId, ingredients) => {
    console.log("--------------------------filterIngredients");
    if (mealServices.has(mealId)) {
        console.log("----------------------------------deletedIngredients");
        const deletedIngredients = mealServices.get(mealId).getDeletedIngredients();
        console.log(deletedIngredients);
        return ingredients.filter(it => !deletedIngredients.has(it.id));
    }
    return ingredients;
};

export const isIngredientReplaced = (mealId, ingredientId) => {
    if (mealServices.has(mealId)) {
        return mealServices.get(mealId).isIngredientReplaced(ingredientId);
    }
    return false;
};

export const getIngredientReplaced = (mealId, ingredientId) => {
    if (mealServices.has(mealId)) {
        return mealServices.get(mealId).getIngredientReplaced(ingredientId);
    }
};

export const setIngredientToReplace = (mealId, initialIngredientId, ingredient) => {
    console.log("----------------------------setIngredientToReplace");
    console.log(mealServices.has(mealId));
    if (mealServices.has(mealId)) {
        mealServices.get(mealId).setIngredientToReplace(initialIngredientId, ingredient);
        mealServices.get(mealId).notifyItemsChangingObservers();
    }
};

export const deleteIngredient = (mealId, ingredientId) => {
    console.log("----------------------------deleteIngredient");
    console.log(mealServices.has(mealId));
    if (mealServices.has(mealId)) {
        mealServices.get(mealId).deleteIngredient(ingredientId);
        mealServices.get(mealId).notifyItemsChangingObservers();
    }
};
