import React from 'react';

import MealItemLayout from "./MealItemLayout";

const DayItemLayout = ({ currentPlan }) => {
    try {
        return (
            currentPlan.map((it) => (
                <MealItemLayout key={it.id} mealItem={it}/>
            ))
        );
    } catch (err) {
        console.log(err);
        return null;
    }
};

export default DayItemLayout;
